<template>
  <component :is="'GCheckbox'" v-bind="{ ...$attrs, ...$props, id: buttonID }" v-on="$listeners" class="g-switch">
    <template v-slot:checkbox="{ checked }">
      <div class="track" :class="{ rounded, checked }">
        <div class="flex-spacer"></div>
        <div class="thumb" :class="{ rounded }">
          <slot name="thumb" />
        </div>
      </div>
    </template>

    <slot v-for="(_, name) in $slots" :name="name" />

    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
/**
 * @version 1.0.0
 */
import GCheckbox from './GCheckbox.vue';

export default {
  name: 'GSwitch',
  extends: GCheckbox,
  inheritAttrs: false,
  components: {
    GCheckbox,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonID() {
      return this.id || `g-switch-${this._uid}`;
    },
  },
};
</script>

<style lang="scss">
.g-switch {
  .track {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 55px;
    height: 24px;
    // background-color: rgb(163, 163, 163);
    border: 2px solid #fff;
    transition: background-color 200ms;
    will-change: background-color;

    &.rounded {
      border-radius: 9999px;
    }
    &.checked {
      // background-color: rgb(61, 184, 255);

      // This one's a little goofy. I'm moving the track by setting the flex-grow on this spacer once checked
      // It just allows for the thumb to be customized with less hardcoded parameters
      .flex-spacer {
        flex: 1;
      }
    }

    .thumb {
      box-sizing: border-box;
      display: table;
      align-items: center;
      justify-content: center;
      width: 19px;
      height: 19px;
      margin: 0 1px;
      content: '';
      // background-color: rgb(53, 54, 65);
      background-image: url('../assets/ford/Globe.svg?external');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      &.rounded {
        border-radius: 9999px;
      }
    }
    .flex-spacer {
      transition: flex 200ms;
      will-change: flex;
    }
  }
}
</style>
