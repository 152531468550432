<template>
  <div class="login">
    <div class="logo-container">
      <BroncoLogo class="digital-logo" />
      <h1>{{ $t('appName') }}</h1>
    </div>
    <div class="button-container">
      <PassportButton :label="$t('signIn.button')" @click="$router.push('/login')" />
      <!-- <PassportButton variant="outline" label="get started" @click="$router.push('/register')" class="started-button" /> -->
      <div class="language-switch-area">
        <span>EN</span>
        <PassportSwitch
          rounded
          class="switch"
          @click="english = !english"
          v-bind:checked="!english"
          :true-value="!english"
          :false-value="english"
          hide-label
        />
        <span>FR</span>
      </div>
    </div>
  </div>
</template>

<script>
// import { ApiService } from '@/services/api.service';
import PassportButton from '@/components/PassportButton.vue';
import PassportSwitch from '@/components/PassportSwitch.vue';
import BroncoLogo from '@/assets/ford/Home_Screen_Logo.svg';

export default {
  name: 'MainPage',
  components: {
    // ApiService,
    PassportButton,
    PassportSwitch,
    BroncoLogo,
  },
  computed: {
    english: {
      get() {
        const value = this.$store.state.locale;
        return value === 'en';
      },
      set(value) {
        this.$store.commit('setLocale', value ? 'en' : 'fr');
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: 0;
  color: #fff;
  transform: translate(-50%, -50%);
}
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin: 28px 0 44px 0;
  }
}
.main-logo {
  width: 3.875em;
  height: 1.875em;
}
.digital-logo {
  width: 14.5em;
  margin-top: 40px;
  // margin-bottom: 40px;
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.language-switch-area {
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}
</style>
